
import { BoxMaintenancePage } from './index.box';
import { CloudMaintenancePage } from './index.cloud';
import { DemoMaintenancePage } from './index.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class MaintenanceIndex extends platform.mixins(environment.nexus, {
  box: BoxMaintenancePage,
  cloud: CloudMaintenancePage,
  demo: DemoMaintenancePage
}) { }
