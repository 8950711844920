import maintenanceBloc from "../../services/bloc";
import { Maintenance } from "../../services";
import { Vue } from 'vue-class-component';
import { ElLoading } from "element-plus";
import moment from "moment";


export abstract class MaintenanceIndexPage extends Vue {
    maintenanceBloc = maintenanceBloc
    options: any = {}
    dt: any;
    maintenance = new Maintenance
    isSearching = false;
    headers: any = {
        responseType: "json",
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    async mounted() {
        const loader = ElLoading.service({ lock: true });
        this.updateHeaders();
        await this.buildDataTable();
        loader.close();
    }

    updateHeaders() {};

    async buildDataTable() {
        const datatableUrl = await this.maintenanceBloc.getMaintenanceUrlBySite();

        const scope: any = this;
        this.options = {
            mobileView: {
                header: {
                    actions: [
                        {
                            id: 'viewAction',
                            template: '<i class="far fa-eye text-success" title="View" ></i> View',
                        },
                        {
                            id: 'editAction',
                            template: '<i class="fas fa-edit text-primary" title="View" ></i> Update',
                        },
                        {
                            id: 'downloadAction',
                            template: '<i class="fas fa-file-download text-warning" title="Generate PDF" ></i> Generate PDF',
                        },
                        {
                            id: 'deleteAction',
                            template: '<i class="far fa-trash-alt text-danger" title="Delete" ></i> Delete',
                        },
                    ],
                    title: function (data: any) {
                        return data.equipment_name;
                    },
                }
            },
            serverSide: true,
            searching: !this.isSearching,
            ajax: {
                url: datatableUrl,
                method: 'GET',
                dataType: 'json',
                dataFilter: (data) => {
                    const parsedData = JSON.parse(data);
                    return JSON.stringify(parsedData.data);
                },
                headers: this.headers,
            },
            columnDefs: [
                {
                    "targets": -1,
                    "width": "1%"
                },
            ],
            columns: [
                { data: 'user_name', mobileView: true },
                { data: 'equipment_name', mobileView: false },
                { data: 'name', mobileView: true },
                {
                    data: 'remarks',
                    mobileView: true,
                    render: function (data, type, row) {
                        return data.length > 40 ?
                            data.substr(0, 40) + '…' :
                            data;
                    }
                },
                {
                    data: 'start_date',
                    mobileView: true,
                    render: function (data, type, row) {
                        return moment(data).format('DD/MM/YYYY');
                    }
                },
                {
                    title: "Action",
                    data: "id",
                    mobileView: false,
                    render: function (id: any, type: any, full: any, meta: any) {
                        return (
                            '<div class="form-group" style="margin: auto;">' +
                            '<div class="btn-group" role="group">' +
                            `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye" data-placement="top" title="View" ></i></button>` +
                            `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" data-placement="top" title="Edit"></i></button>` +
                            `<button type="button" class="btn btn-warning btn-sm download" id="downloadAction"><i class="fas fa-file-download" data-placement="top" title="Generate PDF"></i></button>` +
                            `<button type="button" class="btn btn-danger btn-sm delete"id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
                            "</div>" +
                            "</div>"
                        );
                    }
                }
            ],
            createdRow: function (row: any, data: any) {
                $(row).on("click", "#viewAction", scope.viewData.bind(scope, data));
                $(row).on("click", "#editAction", scope.editData.bind(scope, data));
                $(row).on("click", "#deleteAction", scope.deleteData.bind(scope, data));
                $(row).on("click", "#downloadAction", scope.downloadData.bind(scope, data));
            }
        };
    }

    getFilter(filter: any) {
        this.maintenance = filter;
        this.searching();
    }

    searching() { };

    dtInstance(dt) {
        this.dt = dt;
    }

}

