import { MaintenanceCreatePage } from './create';
import { widget } from '@cems-eco/core/src/utils';
import { ElLoading } from "element-plus";

export class DemoMaintenanceCreatePage extends MaintenanceCreatePage {

    mounted(): void {
        this.maintenance.client_id = 'client';
        this.maintenance.site_id = 'site';
        this.maintenance.user_id = 'user';
        this.maintenance.serial_number = 'SR/2024/0001';
    }

    async save() {
        const loader = ElLoading.service({ lock: true });
        try {
            loader.close();
            await widget.alertSuccess('Good Job!', 'You have successfully created a Maintenance');
            this.$router.push(`/maintenance/id`);
        } catch (error) {
            loader.close();
            widget.alertError(error);
        }
    }
}