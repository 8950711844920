import { Vue, Options } from 'vue-class-component';
import { Maintenance } from "../../services";
import maintenanceBloc from "../../services/bloc";
import component from "../../components/component";


@Options({
    components: component,
})
export class MaintenanceCreatePage extends Vue {
    maintenanceBloc = maintenanceBloc
    maintenance = new Maintenance;
    
    beforeCreate(): void {
        this.maintenance.part_supplied = [];
    }

}