import { BaseMaintenanceViewPage } from './view';
import { widget } from '@cems-eco/core/src/utils';
import { ElLoading } from "element-plus";

export class CloudMaintenanceViewPage extends BaseMaintenanceViewPage {

    async mounted() {
        const loader = ElLoading.service({ lock: true });

        this.maintenance.id = this.$route.params.id
        this.maintenance = await this.maintenanceBloc.getMaintenance(this.maintenance.id);
        this.updateDatetimeInput();

        loader.close();
    }

    async remove() {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.');
                    return;
                }
                let message = await this.maintenanceBloc.destroyMaintenance(this.maintenance.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.$router.replace('/maintenance');
            } catch (error) {
                widget.alertError(error);
            }
        })
    }
}