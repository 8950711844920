import { widget } from "@cems-eco/core/src/utils";
import { MaintenanceIndexPage } from ".";
import storage from "@/storage";
import { ISiteStorage } from "@/storage/model";
import { Options } from "vue-class-component";
import component from "../../components/component";
import { ElLoading } from "element-plus";
import { Subscription } from "rxjs";
import moment from "moment";


@Options({
    components: component,
})
export class BoxMaintenancePage extends MaintenanceIndexPage {

    updateHeaders(): void {
        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            this.headers.Authorization = `Bearer ${auth["access_token"]}`;
        }
    }

    async searching() {
        const loader = ElLoading.service({ lock: true });
        
        const searchUrl = await this.maintenanceBloc.searchParams(this.maintenance);
        this.dt.ajax.url(searchUrl);
        this.dt.ajax.reload();

        loader.close();
    }

    async downloadData(data: any) {
        const loader = ElLoading.service({ lock: true });
        try {
            await this.maintenanceBloc.downloadFile('maintenance-file.pdf', data.id);
            loader.close();
        } catch (error) {
            widget.alertError(error);
            loader.close();
        }
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.')
                    return;
                }
                let message = await this.maintenanceBloc.deleteRelation(data.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.buildDataTable();
            } catch (error) {
                widget.alertError(error);
            }
        })
    }

    async viewData(data: any) {
        this.$router.push(`/maintenance/${data.id}`);
    }

    async editData(data: any) {
        this.$router.push(`/maintenance/${data.id}/edit`);
    }
}

