import { BaseMaintenanceViewPage } from './view';
import { widget } from '@cems-eco/core/src/utils';

export class DemoMaintenanceViewPage extends BaseMaintenanceViewPage {

    mounted() {
        this.maintenance = {
            id: "1",
            _method: "",
            name: "6",
            client_id: "client",
            site_id: "site",
            equipment_id: "equipment",
            user_id: "user",
            diagnosed: "System outdated",
            remarks: "Update software",
            progress: "0",
            part_supplied: [
                {
                    part_number: "PART/0001",
                    description: "Part Description",
                    quantity: 1,
                    remarks: "Part Remarks",
                }
            ],
            start_date: "2021-09-10",
            end_date: "2021-09-10",
            start_time: "22:00",
            end_time: "23:16",
            additional: "",
            serial_number: "SR/2021/0003",
            tag_number: "-",
            next_service_reminder: "2021-10-10",
        };
    }

    async remove() {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.');
                    return;
                }
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.$router.replace('/maintenance');
            } catch (error) {
                widget.alertError(error);
            }
        })
    }
}