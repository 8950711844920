import { widget } from '@cems-eco/core/src/utils';
import { MaintenanceCreatePage } from './create';
import { ElLoading } from "element-plus";
import storage from '@/storage';
import { IUserStorage } from '@/storage/model';
import { Subscription } from 'rxjs';


export class CloudMaintenanceCreatePage extends MaintenanceCreatePage {
    subscription: Subscription;

    async mounted(): Promise<void> {
        await this.getSite();
        await this.getUser();
        await this.getSerialNumber();
    }

    beforeUnmount() {
        this.subscription?.unsubscribe();
    }

    private async getSite() {
        const siteStorage = await this.maintenanceBloc.getSiteStorage();

        this.subscription = siteStorage.subscribe(async (site) => {
            if (Object.keys(site).length) {
                this.maintenance.client_id = site.client_id;
                this.maintenance.site_id = site.id;
            }
        });
    }

    private async getUser() {
        const userStorage: IUserStorage = await storage.get<IUserStorage>(storage.USER);
        this.maintenance.user_id = userStorage.id;
    }

    private async getSerialNumber() {
        const serialNumber: any = await this.maintenanceBloc.serialNumber();
        this.maintenance.serial_number = serialNumber;
    }

    async save() {
        const loader = ElLoading.service({ lock: true });
        try {
            this.maintenance = await this.maintenanceBloc.storeRelation(this.maintenance);
            loader.close();
            await widget.alertSuccess('Good Job!', 'You have successfully created a Maintenance');
            this.$router.push(`/maintenance/${this.maintenance.id}`);
        } catch (error) {
            loader.close();
            widget.alertError(error);
        }
    }
}