
import { BoxMaintenanceViewPage } from './view.box';
import { CloudMaintenanceViewPage } from './view.cloud';
import { DemoMaintenanceViewPage } from './view.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class MaintenanceView extends platform.mixins(environment.nexus, {
    box: BoxMaintenanceViewPage,
    cloud: CloudMaintenanceViewPage,
    demo: DemoMaintenanceViewPage
}) { }
