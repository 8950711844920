import { platform, widget } from "@cems-eco/core/src/utils";
import $ from "jquery";
import { MaintenanceIndexPage } from ".";
import { Options } from "vue-class-component";
import component from "../../components/component";
import { ElLoading } from "element-plus";


@Options({
    components: component as any,
})
export class DemoMaintenancePage extends MaintenanceIndexPage {
    async mounted() {
        const loader = ElLoading.service({ lock: true });

        await this.buildDataTable();

        loader.close();
    }

    async buildDataTable() {
        const scope: any = this;
        this.options = {
            mobileView: {
                header: {
                    actions: [
                        {
                            id: 'viewAction',
                            template: '<i class="far fa-eye text-success" title="View" ></i> View',
                        },
                        {
                            id: 'editAction',
                            template: '<i class="fas fa-edit text-primary" title="View" ></i> Update',
                        },
                        {
                            id: 'downloadAction',
                            template: '<i class="fas fa-file-download text-warning" title="Generate PDF" ></i> Generate PDF',
                        },
                        {
                            id: 'deleteAction',
                            template: '<i class="far fa-trash-alt text-danger" title="Delete" ></i> Delete',
                        },
                    ],
                    title: function (data: any) {
                        return data[1]
                    },
                }
            },
            columns: [
                { mobileView: true },
                { mobileView: true },
                { mobileView: false },
                { mobileView: true },
                { mobileView: true },
                { mobileView: false },
            ],
            data: [
                [
                    "Zairul Izran Mohd Nizam",
                    "GAS ANALYZER (6500160)",
                    "Preventive Maintenance",
                    "Update software",
                    "10/09/2021",
                    '<div class="form-group" style="margin: auto;">' +
                    '<div class="btn-group" role="group">' +
                    `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye" data-placement="top" title="View" ></i></button>` +
                    `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" data-placement="top" title="Edit"></i></button>` +
                    `<button type="button" class="btn btn-warning btn-sm download" id="downloadAction"><i class="fas fa-file-download" data-placement="top" title="Generate PDF"></i></button>` +
                    `<button type="button" class="btn btn-danger btn-sm delete" id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
                    "</div>" +
                    "</div>"
                ],
                [
                    "Shahrin Nidzam",
                    "GAS ANALYZER (6500160)",
                    "Preventive Maintenance",
                    "Update software",
                    "10/09/2021",
                    '<div class="form-group" style="margin: auto;">' +
                    '<div class="btn-group" role="group">' +
                    `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye" data-placement="top" title="View" ></i></button>` +
                    `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" data-placement="top" title="Edit"></i></button>` +
                    `<button type="button" class="btn btn-warning btn-sm download" id="downloadAction"><i class="fas fa-file-download" data-placement="top" title="Generate PDF"></i></button>` +
                    `<button type="button" class="btn btn-danger btn-sm delete" id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
                    "</div>" +
                    "</div>"
                ],
            ],
            columnDefs: [
                {
                    "targets": -1,
                    "width": "1%"
                },
            ],
            createdRow: function (row: any, data: any) {
                $(row).on("click", "#viewAction", scope.viewData.bind(scope, data));
                $(row).on("click", "#editAction", scope.editData.bind(scope, data));
                $(row).on("click", "#deleteAction", scope.deleteData.bind(scope, data));
                $(row).on("click", "#downloadAction", scope.downloadData.bind(scope, data));
            }
        };
    }

    async searching() {
        const loader = ElLoading.service({ lock: true });

        if (this.maintenance.user_id || this.maintenance.equipment_id || this.maintenance.name || this.maintenance.remarks) {
            this.dt.row(0).remove();
            this.dt.draw();
        } else {
            location.reload();
        }

        loader.close();
    }

    async downloadData(data: any) {
        const loader = ElLoading.service({ lock: true });
        try {
            setTimeout(() => {
                loader.close();
            }, 1000);
        } catch (error) {
            loader.close();
        }
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.')
                    return;
                }
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
            } catch (error) {
                widget.alertError(error);
            }
        })
    }

    async viewData(data: any) {
        this.$router.push(`/maintenance/1`);
    }

    async editData(data: any) {
        this.$router.push(`/maintenance/1/edit`);
    }
}

