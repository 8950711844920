
import { BoxMaintenanceCreatePage } from "./create.box";
import { CloudMaintenanceCreatePage } from "./create.cloud";
import { DemoMaintenanceCreatePage } from "./create.demo";

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class MaintenanceEdit extends platform.mixins(environment.nexus, {
  box: BoxMaintenanceCreatePage,
  cloud: CloudMaintenanceCreatePage,
  demo: DemoMaintenanceCreatePage,
}) {}
