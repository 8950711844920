import maintenanceBloc from "../../services/bloc";
import { Maintenance } from "../../services";
import { widget } from "@cems-eco/core/src/utils";
import { Vue, Options } from 'vue-class-component';
import component from "../../components/component";


@Options({
    components: component,
})
export class BaseMaintenanceViewPage extends Vue {
    maintenanceId: any;
    maintenanceBloc = maintenanceBloc
    maintenance = new Maintenance;

    updateDatetimeInput() {
        this.maintenance.start_date = this.maintenance.start_date.slice(0, 10);
        this.maintenance.end_date = this.maintenance.end_date.slice(0, 10);
        this.maintenance.next_service_reminder = this.maintenance.next_service_reminder.slice(0, 10);
        this.maintenance.start_time = this.maintenance.start_time.slice(0, 5);
        this.maintenance.end_time = this.maintenance.end_time.slice(0, 5);
    }
}
